
import API from "../../api/api";


const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getRankclsHistories = async (page, limit, sortBy, direction,classId) => {
    const response = await callApi(`rank-class-history?page=${page}&perPage=${limit}&sortBy=${sortBy}&direction=${direction}&classId=${classId}`)
    return response
}
export const getRankClasses=async()=>{
    const response=await callApi('user-rank-class')
    return response
}